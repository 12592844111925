const json = require('./Details.json');

export const Bio = json.Bio;

export const skills = json.Skills;

export const experiences = json.Experiences;

export const education = json.Education;

export const projects = json.Projects;